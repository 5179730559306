export const requiredValidator = (value) => value.length <= 0
export const maxLengthValidator = (value, maxLength) => value.length >= maxLength
export const regexValidator = (value, regex) => regex.test(value)

// Method to check plain validation
export const validators = (value, required, maxLength, regex) => {
  if (required && maxLength) {
    return (
      requiredValidator(value) ||
      maxLengthValidator(value, maxLength) ||
      !regexValidator(value, regex)
    )
  }
  if (maxLength) {
    return maxLengthValidator(value, maxLength)
  }
  if (required) {
    return requiredValidator(value)
  }
  if (regex) {
    return !regexValidator(value, regex)
  }
}
