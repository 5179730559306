<template>
  <div>
    <p v-if="label || sublabel" class="title-label">
      {{ label }} <span class="sublabel">{{ sublabel }}</span>
    </p>
    <!-- Checkbox list -->
    <div class="input-card-list" :class="classList">
      <template v-for="(checkbox, index) in checkboxList">
        <div :key="index" class="w-full">
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label
            class="app-input-checkbox"
            :class="{ disabled }"
            role="checkbox"
            :tabindex="index"
            :aria-checked="checkbox.value"
            :aria-disabled="checkbox.disabled"
          >
            <input
              v-model="selectedValues"
              class="check-input"
              :value="checkbox"
              type="checkbox"
              :disabled="selectedGroupId && selectedGroupId !== checkbox.groupId"
            />
            <AppRipple class="halo" is-centered :is-disabled="disabled">
              <span class="checkbox">
                <AppIcon class="icon" color="white" size="12">
                  <IconCheckmarkFat />
                </AppIcon>
              </span>
            </AppRipple>
            <div class="label">
              {{ checkbox.text }}
            </div>
          </label>
          <!-- Prompting the user to input their choice for the "other" checkbox -->
          <AppInputText
            v-if="customInput && checkbox.value === 'other' && isOtherSelected"
            v-model="customInputText"
            :class="inputErrorClass"
            class="mt-3"
            type="text"
            placeholder="Other"
            :helper-text="textError"
            @input="otherInput"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { IconCheckmarkFat } from '@moneytransfer.ui/euronet-icons'
import { validators } from '@/utils/validation'
import { mergeArrays, sortArrayToMatchStructure } from '@/utils/array'

import AppIcon from '@/components/AppIcon/AppIcon.vue'
import AppInputText from '@/components/AppInputText/AppInputText'

export default {
  name: 'XeInputCheckboxList',
  components: {
    AppIcon,
    AppInputText,
    IconCheckmarkFat,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    customInput: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    // TODO: improve this - Vue 3 migration
    required: {
      type: Boolean,
      default: true,
    },
    maxLength: {
      type: Number,
      default: 101,
    },
  },
  setup(props, { emit }) {
    // Keep the multi select values
    const selectedValues = ref(props.value)
    const groupId = props.value.length > 0 ? props.value[0].groupId : null
    const selectedGroupId = ref(groupId)
    const customInputText = ref('')
    const hasError = ref(false)

    // Programmatically hide the border of the container
    const classList = computed(() => (props.hideBorder ? 'app-input-checkbox-hide-border' : null))

    // Get checkbox list
    const checkboxList = computed(() => mergeArrays(props.data, selectedValues.value, 'value'))

    // Verify if "other" is selected and return a true or false statement
    const isOtherSelected = computed(() =>
      selectedValues.value.some((item) => item.value === 'other')
    )
    // Error handler for custom errors
    const textError = computed(() => (hasError.value ? props.helperText : ''))
    const inputErrorClass = computed(() => (hasError.value ? 'input--error' : ''))
    const validation = (value) => {
      // error handler
      const validator = validators(
        value,
        props.required,
        props.maxLength,
        /^[a-zA-Z][a-zA-Z0-9 -.,_]*$/ // Improve validation - vue 3 migration
      )
      hasError.value = validator
      emit('inputListError', validator)
    }

    // If the user chooses "other", automatically fill in a saved value into the input field
    selectedValues.value.forEach((item) => {
      if (item.value === 'other') {
        // Set input data using selected value
        customInputText.value = item.checkedValue.replace(/^Other - +/i, '')
        // Set error value checking length of checkedValue
        hasError.value = !item.checkedValue.length > 0
        // Emit error value
        emit('inputListError', !item.checkedValue.length > 0)
      }
    })

    // Adding listener to detect changes in custom input and dynamically updating text based on input values
    const otherInput = (val) => {
      customInputText.value = val
      selectedValues.value.map((item) => {
        if (item.value === 'other') {
          // Set checked value using custom input data
          val.length > 0 ? (item.checkedValue = `Other - ${val}`) : (item.checkedValue = '')
          // error handler
          validation(val)
        }
      })
    }

    // Watching changes in input error to emit validation in order to handle it in parent component
    watch(hasError, (value) => emit('inputListError', value), { deep: true })
    watch(
      isOtherSelected,
      (status) => (status ? validation(customInputText.value) : emit('inputListError', status)),
      { deep: true }
    )

    // Monitoring changes in selected values to emit the user's choice
    watch(
      selectedValues,
      (newValue) => {
        emit('input', sortArrayToMatchStructure(newValue, props.data, 'value'))
        if (newValue.length === 0) {
          selectedGroupId.value = null
        } else {
          selectedGroupId.value = newValue[0].groupId
        }
      },
      { deep: true }
    )

    return {
      selectedValues,
      selectedGroupId,
      classList,
      otherInput,
      customInputText,
      isOtherSelected,
      checkboxList,
      hasError,
      textError,
      inputErrorClass,
    }
  },
}
</script>

<style lang="postcss" scoped>
.app-input-checkbox {
  @apply relative flex items-center;
  input[type='checkbox'] {
    @apply hidden;
  }
  .halo {
    @apply inline-flex justify-center items-center w-6 h-6 rounded-sm bg-transparent;
    flex-shrink: 0;
    &:after {
      content: '';
      @apply absolute w-6 h-6 bg-gray-lighter rounded-sm;
      @apply transform scale-0 transition-transform ease-in-out duration-180;
    }
    .md-ripple-enter {
      @apply opacity-100;
    }
    .md-ripple-wave {
      @apply bg-gray-lighter;
    }
    .checkbox {
      @apply inline-flex justify-center items-center w-5 h-5 border-2 rounded-sm border-text-gray-secondary z-10;
      flex-shrink: 0;
      .icon {
        @apply opacity-0 transition-opacity duration-180;
      }
    }
  }
  input[type='checkbox']:checked ~ .halo {
    &:after {
      @apply bg-orange-lighter;
    }
    .md-ripple-wave {
      @apply bg-orange-lighter;
    }
    .checkbox {
      @apply bg-orange border-orange;
      .icon {
        @apply opacity-100;
      }
    }
  }
  input[type='checkbox']:disabled ~ .halo {
    &:after {
      @apply hidden;
    }
    .checkbox {
      @apply border-gray-light;
    }
  }
  input[type='checkbox']:checked:disabled ~ span {
    @apply bg-gray-light border-transparent;
  }
  &:hover .halo:after,
  &:focus .halo:after {
    @apply scale-100;
  }
  &.disabled {
    @apply cursor-not-allowed;
  }
  &:focus {
    @apply outline-none;
  }
}

.input-card-list {
  @apply flex flex-col items-start p-6 mb-6 gap-4 border-solid border-tertiary-grey-darkest !important;
  border: 0.5px;
  left: calc(50% - 343px / 2 - 0.5px);
  border: 1px;
  box-shadow: 0px 3px 15px rgba(0, 17, 51, 0.05);
  border-radius: 16px;

  &.app-input-checkbox-hide-border {
    @apply border-none p-0  mb-0 shadow-none !important;
  }
}

.title-label {
  @apply text-main-black text-base font-medium p-1;
}

.check-input {
  @apply text-base text-gray-primary;
}

.label {
  @apply text-gray-primary pl-4;
  line-height: 24px;
  font-size: 15px;
  flex-grow: 1;
}
.sublabel {
  @apply font-normal;
}

>>> .input-helper {
  color: rgba(237, 95, 116, 1);
}
</style>
